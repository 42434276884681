import { Dispatch, SetStateAction } from 'react'

import { deleteUndefined, removeByIndex, replaceFieldsWithUndefined } from '@jume/utils'
import { RealignmentItem, RealignmentUpdateItem, RealignmentUpdateParameters } from 'demand/realignment/api'
import { clone, isEqual, uniq } from 'lodash'
import { devtools } from 'packages/core'
import { create } from 'zustand'

export interface UseRealignmentTable extends Required<RealignmentUpdateParameters> {
  selectedIds: number[]
  setSelectedIds: Dispatch<SetStateAction<number[]>>
  initialData: RealignmentItem[]
  setInitialData: (initialData: RealignmentItem[]) => void
  toUpdate: (id: number, data: Partial<Omit<RealignmentUpdateItem, 'id'>>) => void
  toDelete: (id: number) => void
  manyToUpdate: (ids: number[], data: Partial<Omit<RealignmentUpdateItem, 'id'>>) => void
  manyToDelete: (ids: number[]) => void
  clear: () => void
}

const toUpdate = (id: number, data: Partial<Omit<RealignmentUpdateItem, 'id'>>, prev: UseRealignmentTable) => {
  const initialData = prev.initialData.find((item) => item.id === id)
  const isChanged = !isEqual(data, initialData)
  const foundIndex = prev.update?.findIndex((item) => item.id === id)
  if (foundIndex > -1) {
    prev.update[foundIndex] = deleteUndefined({
      ...prev.update[foundIndex],
      ...(isChanged ? data : replaceFieldsWithUndefined(data)),
    })
    if (isEqual(Object.keys(prev.update[foundIndex]), ['id'])) {
      prev.update = removeByIndex(prev.update, foundIndex)
    }
  } else if (isChanged) {
    prev.update?.push({ id, ...data })
  }
  return prev
}

export const useRealignmentTable = create<UseRealignmentTable>()(
  devtools(
    (set) => ({
      update: [],
      delete: [],
      selectedIds: [],
      initialData: [],
      setInitialData: (initialData) => set({ initialData }),
      setSelectedIds: (state) => {
        set((prev) => ({ selectedIds: typeof state === 'function' ? state(prev.selectedIds) : state }))
      },
      toUpdate: (id, data) => set((prev) => clone(toUpdate(id, data, prev))),
      toDelete: (id) => set((prev) => ({ delete: uniq([...prev.delete, id]) })),
      manyToUpdate: (ids, data) =>
        set((prev) => {
          ids.forEach((id) => toUpdate(id, data, prev))
          prev.selectedIds = []
          return clone(prev)
        }),
      manyToDelete: (ids) => set((prev) => ({ delete: uniq([...prev.delete, ...ids]), selectedIds: [] })),
      clear: () =>
        set({
          selectedIds: [],
          update: [],
          delete: [],
        }),
    }),
    {
      store: 'demand.realignment.table',
    },
  ),
)
