import { capitalize } from 'lodash'
import { Chart, Diagram, ServerChart, ServerDiagram } from 'prgm/scorecard/interfaces'
import { create } from 'zustand'

interface UseDiagrams {
  diagrams: Diagram[]
  setDiagrams: (resDiagrams: ServerDiagram[]) => void
  refreshDiagrams: () => void
}

export const useDiagrams = create<UseDiagrams>((set) => ({
  diagrams: [],
  setDiagrams: (resDiagrams) =>
    set(() => {
      const keys = ['type', 'subtitle']
      const isYearToYear = (key: string) => key.length < 5
      const monthToIndex: Record<any, number> = {
        jan: 0,
        feb: 1,
        mar: 2,
        apr: 3,
        may: 4,
        jun: 5,
        jul: 6,
        aug: 7,
        sep: 8,
        oct: 9,
        nov: 10,
        dec: 11,
      }

      const getLabelFromKey = (key: string) => {
        if (key.length >= 5) {
          // Дата
          const month = capitalize(key.slice(0, -4))
          const year = key.slice(-4)

          return `${month} ${year}`
        } else {
          // Месяц
          return key
        }
      }

      const resChartToChart = (resChart: ServerChart) => {
        const newChart = {} as Chart
        const dateKeys = Object.keys(resChart).filter((key) => !keys.includes(key))
        const yearToYear = dateKeys?.[0] ? isYearToYear(dateKeys[0]) : false
        newChart.type = resChart.type
        newChart.subtitle = resChart.subtitle
        newChart.data = dateKeys.map((key: any, index) => ({
          x: yearToYear ? monthToIndex[key] : index,
          label: getLabelFromKey(key),
          y: Number(resChart[key].toFixed(2)),
        }))

        return newChart
      }

      const resDiagramToDiagram = (resDiagram: ServerDiagram) => {
        const newDiagram = {} as Diagram
        newDiagram.title = resDiagram.title
        newDiagram.chart1 = resChartToChart(resDiagram.chart1)
        newDiagram.chart2 = resChartToChart(resDiagram.chart2)
        newDiagram.chart3 = resDiagram?.chart3 && resChartToChart(resDiagram.chart3)
        newDiagram.chart4 = resDiagram?.chart4 && resChartToChart(resDiagram.chart4)

        return newDiagram
      }

      const newDiagrams = resDiagrams.map(resDiagramToDiagram)

      return {
        diagrams: newDiagrams,
      }
    }),
  refreshDiagrams: () =>
    set(({ diagrams }) => ({
      diagrams: [...diagrams],
    })),
}))
