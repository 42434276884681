import { useCallback, useContext, useEffect, useState } from 'react'

import { removeElementFromArray } from '@jume/utils'
import { VirtualScrollPosition } from 'interfaces/excelTable.interfaces'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'

export const useCellsSliceY = (paginateAxis: 'x' | 'y', page: number, perPage: number) => {
  const overscan = 500
  const { virtualScroll, tableState } = useContext(ExcelTableContext)

  const getSliceY = useCallback((position?: VirtualScrollPosition): [number, number] => {
    const pageY = paginateAxis === 'x' ? 1 : page
    const prevCount = (pageY - 1) * perPage
    let sliceStart = Math.floor(((position?.startY || 0) - overscan) / tableState.heightRow) - 1 - prevCount
    if (sliceStart < 0) {
      sliceStart = 0
    }
    let sliceEnd =
      Math.ceil(((position?.endY || document.body.offsetHeight) + overscan) / tableState.heightRow) - 1 - prevCount
    if (sliceEnd > pageY * perPage - 1) {
      sliceEnd = pageY * perPage - 1
    }
    return [sliceStart, sliceEnd]
  }, [])

  const [sliceY, setSliceY] = useState<[number, number]>(getSliceY())

  const onChangeScroll = useCallback((position: VirtualScrollPosition) => {
    setSliceY(getSliceY(position))
  }, [])

  useEffect(() => {
    onChangeScroll(virtualScroll.position)
    virtualScroll.onChangeFns.push(onChangeScroll)
    return () => removeElementFromArray(virtualScroll.onChangeFns, onChangeScroll)
  }, [onChangeScroll])

  return { sliceY }
}
