import { global } from '@jume/utils'
import { AxiosError } from 'axios'
import { ApiError } from 'packages/api'
import { CustomAxiosInstance } from 'packages/core/apiConfig/types'
import { RefreshException, refreshTokens } from 'packages/core/auth'

import { RequestQueue } from './requestQueue'
import { RetryRequestController } from './retryRequestController'
import { AxiosExtendedRequestConfig } from './types'

let refreshSent = false

export const createUnauthenticatedRequestInterceptor = (axios: CustomAxiosInstance) => {
  const requestQueue = new RequestQueue(axios)

  return async (axiosError: AxiosError) => {
    const retryRequestController = new RetryRequestController(axiosError)
    const originalRequest = axiosError.config as AxiosExtendedRequestConfig

    if (retryRequestController.isNoRetry()) {
      return Promise.reject(axiosError)
    }

    retryRequestController.parseJsonBody()

    try {
      retryRequestController.init()
      if (!refreshSent) {
        refreshSent = true
        const { tokens } = await refreshTokens(axios)
        retryRequestController.setAccessToken(tokens.accessToken)
        requestQueue.setAccessToken(tokens.accessToken)
        refreshSent = false
        requestQueue.runQueue()
        return await axios.request(originalRequest)
      } else {
        return await requestQueue.executeByQueue(originalRequest)
      }
    } catch (error) {
      if (error instanceof RefreshException) {
        global.logout?.()
      }
      delete originalRequest.headers.Authorization
      return Promise.reject({
        ...(error as ApiError),
        isRetryError: true,
      })
    }
  }
}
