import { deleteEmptyInArray } from '@jume/utils'
import { cloneDeep, isArray } from 'lodash'
import { Views } from 'prgm/scorecard/constants'
import { ScorecardFilters } from 'prgm/scorecard/interfaces'
import { create } from 'zustand'

interface UseScorecardSettings {
  filters: ScorecardFilters
  setFilters: (filters: ScorecardFilters) => void
  clear: () => void
}

export const useScorecardSettings = create<UseScorecardSettings>((set) => ({
  filters: {
    view: Views.Linear,
  },
  setFilters: (filters) =>
    set(() => {
      const newFilters = cloneDeep(filters)
      if (!newFilters?.periodStart?.length) {
        delete newFilters?.periodStart
      }
      if (!newFilters?.periodEnd?.length) {
        delete newFilters?.periodEnd
      }
      Object.keys(newFilters).forEach((key) => {
        const filterKey = key as keyof ScorecardFilters
        if (isArray(newFilters[filterKey])) {
          const parsedValue = deleteEmptyInArray(newFilters[filterKey] as string[]) as any
          if (parsedValue.length) {
            newFilters[filterKey] = parsedValue
          } else {
            delete newFilters[filterKey]
          }
        }
      })

      return { filters: newFilters }
    }),
  clear: () =>
    set({
      filters: {
        view: Views.Linear,
      },
    }),
}))
