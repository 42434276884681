export const updateScenarioMs = 5000 // (ms) Должно быть больше 1000

export const inputMasks = {
  date: '##.##.####',
  dateRange: '##.##.#### — ##.##.####',
  monthRange: '##.#### — ##.####',
  month: '##.####',
}

export const updateSystemMinutes = 15
export const autoCloseToastDelay = 5000
