import { useCallback } from 'react'

import { global } from '@jume/utils'
import { AppContextProps } from 'packages/app/providers'
import { broadcastLogout, useLogoutClear } from 'packages/core/auth/logout'
import { logoutApi } from 'packages/core/auth/logout/api'
import { getTokens } from 'packages/core/auth/tokens'

export const useLogoutByAppContext = (appContext: AppContextProps) => {
  const { logoutClear } = useLogoutClear(appContext.auth.logout, appContext)

  const logout = useCallback(() => {
    const { refreshToken } = getTokens()
    if (refreshToken) {
      logoutApi.logout({ refresh: refreshToken })
    }
    global.logout = () => {
      appContext.auth.setAuthenticated(false)
      logoutClear()
      broadcastLogout()
    }
    global.logout()
  }, [logoutClear, broadcastLogout])

  return { logout }
}
