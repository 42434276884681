import { mouseMoveState } from 'packages/app/hooks/useGlobalMouseMove'

export const checkCursorOnElement = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  return (
    mouseMoveState.pageX >= rect.left &&
    mouseMoveState.pageX <= rect.right &&
    mouseMoveState.pageY >= rect.top &&
    mouseMoveState.pageY <= rect.bottom
  )
}
