import { toNumber } from '@jume/utils'

// Эта функция только для дробных чисел,
// если нужна функция для разделения строки через запятую то используйте parseValuesFromExcel

export const excelStringToArray = <T extends boolean = false>(
  text: string,
  convertToNumber?: T,
): T extends false ? string[][] : (number | null)[][] => {
  if (text.slice(-1) === '\n') {
    text = text.slice(0, -1)
  }
  const lines = text.split('\n')
  const result: any[][] = []

  lines.forEach((line) => {
    const values = line.split('\t')
    if (convertToNumber) {
      result.push(values.map((value) => (value === '' ? null : toNumber(value))))
    } else {
      result.push(values)
    }
  })
  return result as T extends false ? string[][] : (number | null)[][]
}
