import { RefObject, useEffect } from 'react'

import { toArray } from '@jume/utils'

export const useSyncHeight = (
  element?: RefObject<HTMLElement>,
  elementsDependent?: RefObject<HTMLElement> | null | RefObject<HTMLElement>[],
  offset = 0,
  deps?: any[],
  enabled = true,
) => {
  useEffect(() => {
    if (!element?.current || !elementsDependent || !enabled) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        toArray(elementsDependent).forEach((elDependent) => {
          if (elDependent.current) {
            elDependent.current.style.height = `${entry.contentRect.height + offset}px`
          }
        })
      })
    })
    resizeObserver.observe(element.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [element?.current, elementsDependent, enabled, ...(deps || [])])
}
