import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const deploySessionModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    session: {
      label: () => t('deploy.navMenu.session'),
      initialOpen: true,
      items: [
        {
          label: () => t('deploy.navMenu.session.management'),
          href: '/deploy/data-hub',
        },
        {
          label: () => t('deploy.navMenu.scenarios.history'),
          href: `/${Streams.Deploy}/scenarios-history`,
        },
      ],
    },
  },
})
