import {
  CSSProperties,
  forwardRef,
  Fragment,
  HTMLAttributeAnchorTarget,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  TouchEvent,
  TouchEventHandler,
  useMemo,
} from 'react'

import { Link as NativeLink, LinkProps as NativeLinkProps } from '@tanstack/react-router'
import cx from 'clsx'
import { Path } from 'interfaces/router.interfaces'

export interface LinkProps extends Omit<NativeLinkProps, 'to'> {
  to?: Path | null
  disabled?: boolean
  disabledContainer?: boolean
  className?: string
  classNameDisabled?: string
  target?: HTMLAttributeAnchorTarget
  onClick?: MouseEventHandler<'a' | HTMLSpanElement>
  onClickWhenDisabled?: MouseEventHandler<'a' | HTMLSpanElement>
  onClickWithTouch?: (event: MouseEvent<'a' | HTMLSpanElement> | TouchEvent<'a' | HTMLSpanElement>) => void
  onClickWithTouchWhenDisabled?: (event: MouseEvent<'a' | HTMLSpanElement> | TouchEvent<'a' | HTMLSpanElement>) => void
  onMouseUp?: MouseEventHandler<'a' | HTMLSpanElement>
  onMouseDown?: MouseEventHandler<'a' | HTMLSpanElement>
  dataCurrent?: boolean
  children?: ReactNode
  style?: CSSProperties
  id?: number
  preventDefault?: boolean
  stopPropagation?: boolean
  dataStopPropagation?: boolean
  download?: boolean
  title?: string
  itemProp?: string
  itemScope?: boolean
  itemType?: string
  itemID?: string
  showIconTargetBlank?: boolean
}

const closureIsTouched = () => {
  let isTouched = false

  const getIsTouched = () => isTouched
  const setIsTouched = (newReady: boolean) => {
    isTouched = newReady
  }

  return { getIsTouched, setIsTouched }
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      to,
      disabled,
      disabledContainer,
      className,
      classNameDisabled,
      target,
      onClick,
      onClickWhenDisabled,
      onClickWithTouch,
      onClickWithTouchWhenDisabled,
      onMouseUp,
      onMouseDown,
      dataCurrent,
      style,
      id,
      preventDefault,
      stopPropagation,
      dataStopPropagation,
      download,
      title,
      itemProp,
      itemScope,
      itemType,
      itemID,
      showIconTargetBlank,
      ...linkProps
    },
    ref,
  ) => {
    const Container = showIconTargetBlank
      ? (props: { children: ReactNode }) => (
          <span className="linkBlank">
            <span className="linkBlankCont">{props.children}</span>
          </span>
        )
      : Fragment

    const { getIsTouched, setIsTouched } = useMemo(closureIsTouched, [])

    const onClickInternal: MouseEventHandler<'a' | HTMLSpanElement> = (e) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (preventDefault) {
        e.preventDefault()
      }
      if (!disabled && onClick) {
        onClick(e)
      }
      if (!disabled && onClickWithTouch && !getIsTouched()) {
        onClickWithTouch(e)
      }
      setIsTouched(false)
    }

    const onClickWhenDisabledInternal: MouseEventHandler<'a' | HTMLSpanElement> = (e) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (preventDefault) {
        e.preventDefault()
      }
      if (onClickWhenDisabled) {
        onClickWhenDisabled(e)
      }
      if (onClickWithTouchWhenDisabled && !getIsTouched()) {
        onClickWithTouchWhenDisabled(e)
      }
      setIsTouched(false)
    }

    const onClickWithTouchInternal: TouchEventHandler<'a' | HTMLSpanElement> = (e) => {
      setIsTouched(true)
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (preventDefault) {
        e.preventDefault()
      }
      if (!disabled && onClickWithTouch) {
        onClickWithTouch(e)
      }
    }

    const onClickWithTouchWhenDisabledInternal: TouchEventHandler<'a' | HTMLSpanElement> = (e) => {
      setIsTouched(true)
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (preventDefault) {
        e.preventDefault()
      }
      if (onClickWithTouchWhenDisabled) {
        onClickWithTouchWhenDisabled(e)
      }
    }

    if (disabledContainer) {
      return children as ReactElement
    }

    return (
      <Container>
        {to && !disabled ? (
          <NativeLink
            className={className}
            data-id={id}
            data-stop-propagation={dataStopPropagation}
            download={download}
            itemID={itemID}
            itemProp={itemProp}
            itemScope={itemScope}
            itemType={itemType}
            onClick={disabled ? onClickWhenDisabledInternal : onClickInternal}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={disabled ? onClickWithTouchWhenDisabledInternal : onClickWithTouchInternal}
            ref={ref}
            style={style}
            target={target}
            title={title}
            to={to}
            {...linkProps}
          >
            {children as ReactElement}
          </NativeLink>
        ) : (
          <span
            className={cx(className, classNameDisabled && { [classNameDisabled]: disabled })}
            data-current={dataCurrent ? '' : undefined}
            data-id={id}
            data-stop-propagation={dataStopPropagation}
            itemID={itemID}
            itemProp={itemProp}
            itemScope={itemScope}
            itemType={itemType}
            onClick={disabled ? onClickWhenDisabledInternal : onClickInternal}
            onTouchStart={disabled ? onClickWithTouchWhenDisabledInternal : onClickWithTouchInternal}
            ref={ref}
            style={style}
            title={title}
          >
            {children as ReactElement}
          </span>
        )}
      </Container>
    )
  },
)
