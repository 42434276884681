import { Streams } from 'constants/streams'
import { deployPpedModule } from 'deploy/pages/pped/deployPpedModule'
import { deployScenarioModule } from 'deploy/pages/scenario/deployScenarioModule'
import { deploySessionModule } from 'deploy/pages/session/deploySessionModule'
import { jobsModule } from 'modules/jobs/jobsModule'
import { StreamConnector } from 'packages/app'
import { t } from 'packages/localization'

const deployModules = {
  session: deploySessionModule(),
  scenarios: deployScenarioModule(),
  pped: deployPpedModule(),
  jobs: jobsModule(undefined, { streamName: Streams.Deploy }),
}

export const connectDeployStream: StreamConnector = () => ({
  name: Streams.Deploy,
  startPage: `/${Streams.Deploy}/data-hub`,
  modules: deployModules,
  menu: (menuBuilder) => {
    menuBuilder.addItem(deployModules.scenarios.menuItems.scenarios)
    menuBuilder.addItem({
      label: () => t('deploy.navMenu.reporting'),
      initialOpen: true,
      items: [
        deployModules.pped.menuItems.pped,
        {
          label: () => t('deploy.navMenu.reporting.executive'),
          href: '/deploy/executive',
        },
      ],
    })
    menuBuilder.addItem(deployModules.session.menuItems.session)
    menuBuilder.addItem(deployModules.jobs.menuItems.jobs)
  },
})
