import { RefObject, useCallback, useEffect } from 'react'

import { copy } from '@jume/utils'
import { ColumnMeta, Table } from '@tanstack/table-core'
import { useUpdateEffect } from 'ahooks'
import { CustomChange } from 'interfaces/common.interfaces'
import { commaFormatNumber, dotFormatNumber } from 'packages/ui/Table/helpers/cellFormat'
import { getBorders } from 'packages/ui/Table/helpers/getBorders'
import { getCellText } from 'packages/ui/Table/helpers/getCellText'
import { Frame } from 'packages/ui/Table/interfaces'

export const useCopyPaste = <TData>(
  active: boolean,
  frame: Frame,
  tableRef: RefObject<HTMLTableElement>,
  copyPaste?: boolean,
  tableInst?: Table<TData>,
) => {
  const { minColumn, maxColumn, minRow, maxRow, width, height } = getBorders(frame)
  const table = tableRef.current
  const rowsAmount = table?.rows.length || 0
  const columnsAmount = table?.rows[0]?.cells.length || 0
  const columnDelimiter = '\t'
  const rowDelimiter = '\n'

  const isCopingFromInput = () => {
    const selection = document.getSelection()
    return width === 1 && height === 1 && selection?.toString()
  }

  const onCopy = useCallback(
    (event: ClipboardEvent) => {
      if (!table || isCopingFromInput()) {
        return
      }
      event.preventDefault()
      let tableContent = ''
      for (let rowIdx = minRow; rowIdx <= maxRow; rowIdx++) {
        const row = table.rows[rowIdx]
        for (let columnIdx = minColumn; columnIdx <= maxColumn; columnIdx++) {
          const cell = row.cells[columnIdx]
          const textContent = getCellText(cell)
          tableContent += commaFormatNumber(textContent)
          if (columnIdx !== maxColumn) {
            tableContent += columnDelimiter
          }
        }
        if (rowIdx !== maxRow) {
          tableContent += rowDelimiter
        }
      }
      copy(tableContent)
    },
    [table, frame],
  )

  const onPaste = useCallback(
    async (event: ClipboardEvent) => {
      if (!table || !event.clipboardData) {
        return
      }
      event.preventDefault()
      const paste = event.clipboardData.getData('text') || (await navigator.clipboard.readText())
      const rows = paste.trim().split(rowDelimiter)
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        const row = rows[rowIdx]
        const cells = row.split(columnDelimiter)
        for (let columnIdx = 0; columnIdx < cells.length; columnIdx++) {
          const cellText = dotFormatNumber(cells[columnIdx].trim())
          if (minRow + rowIdx >= rowsAmount || minColumn + columnIdx >= columnsAmount) {
            continue
          }
          const cellEl = table.rows[minRow + rowIdx].cells[minColumn + columnIdx]
          const input = cellEl?.querySelector('input') as (HTMLInputElement & CustomChange) | null
          if (input) {
            input?.setValue?.(cellText)
          }
          ;(tableInst?.getAllColumns()[minColumn + columnIdx]?.columnDef?.meta as ColumnMeta)?.paste?.(
            minRow + rowIdx - 1,
            minColumn + columnIdx,
            cellText,
          )
        }
      }
    },
    [table, frame, tableInst],
  )

  useEffect(() => {
    if (!table || !active || !copyPaste) {
      return
    }
    document.addEventListener('copy', onCopy)
    document.addEventListener('paste', onPaste)
    return () => {
      document.removeEventListener('copy', onCopy)
      document.removeEventListener('paste', onPaste)
    }
  }, [frame, copyPaste])

  useUpdateEffect(() => {
    if (!copyPaste) {
      return
    }
    const selection = document.getSelection()
    if ((width > 1 || height > 1) && selection?.toString()) {
      selection.empty()
    }
  }, [width, height, copyPaste])
}
