import { ReactNode } from 'react'

import { ApiError } from './apiTypingErrors'

export const getError = <Return extends string | ReactNode = string | ReactNode>(
  error?: unknown | ApiError | null,
): Return => {
  const apiError = error as (ApiError & { msg: string | null }) | null
  if (!apiError) {
    return null as Return
  }
  return (apiError.detail ?? apiError.msg ?? apiError.message ?? null) as Return
}

export const getErrorMessage = (error?: unknown | ApiError | null) => {
  const apiError = error as (ApiError & { msg: string | null }) | null
  if (!apiError) {
    return null
  }
  return (apiError.msg ?? apiError.message ?? null) as string | null
}

export const getOriginalError = <Return extends string | ReactNode = string | ReactNode>(
  error?: unknown | ApiError | null,
): Return => {
  const apiError = error as (ApiError & { msg: string | null }) | null
  if (!apiError) {
    return null as Return
  }
  return (apiError.original?.error ?? getError(error)) as Return
}
