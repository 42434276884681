import {
  ChangeEvent,
  ClipboardEvent,
  FormEvent,
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PatternFormat } from 'react-number-format'
import TextareaAutosize from 'react-textarea-autosize'

import { toNumberString } from '@jume/utils'
import CloseIcon from 'assets/images/close-16.svg?react'
import ErrorIcon from 'assets/images/error.svg?react'
import InfoIcon from 'assets/images/info.svg?react'
import SearchIcon from 'assets/images/search.svg?react'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { ChangeEventCustom, CustomChange, WarningColors } from 'interfaces/common.interfaces'
import { InputProps, TextFieldColors, TextFieldSizes } from 'interfaces/components.interfaces'
import { Loader, LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { Tooltip } from 'packages/ui/Tooltip'
import { antiAutoComplete } from 'utils/antiAutoComplete'
import { numberToLocalString } from 'utils/numberToLocalString'

import classes from './TextField.module.scss'

export const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      classNameContainer,
      classNameAfter,
      classNameFocus,
      classNameTooltip,
      size = TextFieldSizes.Medium,
      color = TextFieldColors.Default,
      autoComplete = 'off',
      name,
      value,
      initialValue,
      placeholder,
      mask = '',
      htmlType = 'text',
      disabled,
      noEditable,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onPaste,
      onCtrlEnter,
      onClick,
      blurOnArrowClick,
      blurOnEnter,
      loading,
      maxLength,
      maxRows,
      multiline,
      autoResize,
      autoFocus,
      noWrap,
      showSearchIcon,
      afterContent = showSearchIcon ? <SearchIcon /> : undefined,
      onClickAfter,
      afterIsClickable,
      isCell,
      isError,
      showErrorIcon,
      showWarningIcon,
      warningColor = WarningColors.Danger,
      tooltip,
      isChanged,
      isCleanable,
      showCleanOnHover,
      positiveNumber,
      integerNumber,
      spellCheck = true,
      noRemoveLeadingZero,
      disableAutoScrollInChrome,
      refWrap,
      maxNumber,
      toLocalString,
      ...rest
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(value ?? initialValue)
    const [isFocused, setIsFocused] = useState(false)

    const refInternal = useRef<(HTMLInputElement | HTMLTextAreaElement) & CustomChange>(null)
    const { cbRef } = useCombinedRef<HTMLInputElement | HTMLTextAreaElement>(ref, refInternal)

    const onChangeInternal = (
      e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEventCustom,
    ) => {
      let newValue = e.target.value
      const valueForNumber = newValue.replace(/,/, '.')

      if (
        htmlType === 'number' &&
        (((!/^[0-9.,-]+$/.test(valueForNumber) || /^\./.test(valueForNumber)) && newValue !== '') ||
          valueForNumber.split('.').length - 1 > 1 ||
          newValue.split('-').length - 1 > 1 ||
          (newValue.length > 1 && newValue.indexOf('-') > 0 && newValue !== '0-') ||
          (positiveNumber && newValue[0] === '-') ||
          (integerNumber && valueForNumber.indexOf('.') >= 0) ||
          (maxNumber && Number(newValue) > maxNumber))
      ) {
        return
      }
      if (htmlType === 'number' && !noRemoveLeadingZero) {
        newValue = toNumberString(newValue) ?? ''
      }
      if (onChange) {
        onChange(newValue)
      }
      setValueInternal(newValue)
    }

    const onFocusInternal = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
      setIsFocused(true)
      if (onFocus) {
        onFocus(e)
      }
    }

    const onBlurInternal = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
      setIsFocused(false)
      if (onBlur) {
        onBlur(e)
      }
    }

    const onWheel = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) =>
      (e.target as HTMLInputElement).blur()

    const onClear = () => {
      onChange?.('')
      // fix onClickAway when input is in dropdown
      requestAnimationFrame(() => {
        setValueInternal('')
      })
    }

    const onKeyDownInternal: KeyboardEventHandler = (event) => {
      const target = event.target as HTMLInputElement

      if (event.key === 'Enter' && event.ctrlKey && multiline) {
        onCtrlEnter?.(event as KeyboardEvent<HTMLInputElement>)
      }
      if (blurOnEnter && event.key === 'Enter' && !event.ctrlKey) {
        target.blur()
      }
      if (blurOnArrowClick && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
        target.blur()
      }
      onKeyDown?.(event as KeyboardEvent<HTMLInputElement>)
    }

    const onPasteInternal = (event: ClipboardEvent<HTMLInputElement> | ClipboardEvent<HTMLTextAreaElement>) => {
      const target = event.target as HTMLInputElement
      onPaste?.(event, (newValue) => {
        target.value = newValue
        setValueInternal(newValue)
      })
    }

    useEffect(() => {
      if (refInternal.current && autoFocus) {
        if (disableAutoScrollInChrome) {
          refInternal.current.focus({ preventScroll: true })
        } else {
          refInternal.current.focus()
        }
      }
    }, [refInternal.current, autoFocus])

    useEffect(() => {
      if (initialValue ? value !== undefined : true) {
        setValueInternal(value)
      }
    }, [value])

    useEffect(() => {
      if (refInternal.current) {
        refInternal.current.setValue = (val: string) => {
          onChangeInternal({ target: { value: val } })
        }
      }
    }, [refInternal.current])

    return (
      <div
        className={cx(classes.wrap, classNameContainer, classes[color], classes[size], {
          disabledInput: disabled,
          [classes.noWrap]: noWrap,
          [classes.isCell]: isCell,
          [classes.isFocused]: isFocused,
          [classes.disabled]: disabled,
          [classes.noEditable]: noEditable,
          [classes.noMultiline]: !multiline,
          [classes.isChanged]: isChanged,
          [classes.error]: isError,
          [classes.withRight]: (isCleanable && valueInternal) || afterContent,
          [classes.showCleanOnHover]: showCleanOnHover,
        })}
        data-copy-text={rest['data-copy-text']}
        onClick={(event) => {
          onClick?.(event)
          ;((ref ?? refInternal) as MutableRefObject<HTMLInputElement | null>).current?.focus()
        }}
        ref={refWrap}
      >
        <div className={classes.cont}>
          {!multiline && !mask && (
            <input
              autoComplete={autoComplete}
              autoFocus={disableAutoScrollInChrome ? undefined : autoFocus}
              className={cx(classes.input, className, classNameFocus && { [classNameFocus]: isFocused }, {
                [classes.withCancel]: isCleanable && valueInternal,
                [classes.withAfter]: afterContent,
                [classes.noEditable]: noEditable,
              })}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              maxLength={maxLength}
              name={name}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDownInternal}
              onPaste={onPasteInternal}
              onWheel={onWheel}
              placeholder={typeof placeholder === 'string' && placeholder ? antiAutoComplete(placeholder) : undefined}
              ref={cbRef}
              role="textbox"
              spellCheck={spellCheck}
              type={htmlType !== 'password' && htmlType !== 'tel' ? 'text' : (htmlType as 'password' | 'tel')}
              value={toLocalString && !isFocused ? numberToLocalString(valueInternal ?? '') : valueInternal ?? ''}
            />
          )}
          {!multiline && mask && (
            <PatternFormat
              autoComplete={autoComplete}
              autoFocus={disableAutoScrollInChrome ? undefined : autoFocus}
              className={cx(classes.input, className, classNameFocus && { [classNameFocus]: isFocused }, {
                [classes.withCancel]: isCleanable && valueInternal,
                [classes.withAfter]: afterContent,
                [classes.noEditable]: noEditable,
              })}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              format={mask}
              getInputRef={cbRef}
              mask="_"
              maxLength={maxLength}
              name={name}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDownInternal}
              onPaste={onPasteInternal}
              onWheel={onWheel}
              placeholder={
                typeof placeholder === 'string' && placeholder
                  ? antiAutoComplete(placeholder)
                  : mask
                    ? mask.replaceAll('#', '_')
                    : undefined
              }
              role="textbox"
              spellCheck={spellCheck}
              type={htmlType !== 'password' && htmlType !== 'tel' ? 'text' : (htmlType as 'password' | 'tel')}
              value={toLocalString && !isFocused ? numberToLocalString(valueInternal ?? '') : valueInternal ?? ''}
            />
          )}
          {multiline && !autoResize && (
            <textarea
              autoFocus={disableAutoScrollInChrome ? undefined : autoFocus}
              className={cx(classes.input, className, classNameFocus && { [classNameFocus]: isFocused }, {
                [classes.withCancel]: isCleanable && valueInternal,
                [classes.withAfter]: afterContent,
                [classes.noEditable]: noEditable,
              })}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              maxLength={maxLength}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDownInternal}
              onPaste={onPasteInternal}
              onWheel={onWheel}
              placeholder={placeholder}
              ref={cbRef}
              role="textbox"
              spellCheck={spellCheck}
              value={toLocalString && !isFocused ? numberToLocalString(valueInternal ?? '') : valueInternal ?? ''}
            />
          )}
          {multiline && autoResize && (
            <TextareaAutosize
              autoFocus={disableAutoScrollInChrome ? undefined : autoFocus}
              className={cx(classes.input, className, classNameFocus && { [classNameFocus]: isFocused }, {
                [classes.withCancel]: isCleanable && valueInternal,
                [classes.withAfter]: afterContent,
                [classes.noEditable]: noEditable,
              })}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              maxLength={maxLength}
              maxRows={maxRows}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDownInternal}
              onPaste={onPasteInternal}
              onWheel={onWheel}
              placeholder={placeholder}
              ref={cbRef}
              role="textbox"
              spellCheck={spellCheck}
              value={toLocalString && !isFocused ? numberToLocalString(valueInternal ?? '') : valueInternal ?? ''}
            />
          )}

          {loading && (
            <Loader
              className={cx(classes.loader, { [classes.after]: afterContent })}
              color={LoaderColors.Gray}
              type={LoaderTypes.SpinnerMini}
            />
          )}
          {afterContent && !(isCleanable && valueInternal) && (
            <div
              className={cx(classes.afterContent, classNameAfter, { [classes.afterIsClickable]: afterIsClickable })}
              onClick={onClickAfter}
            >
              {afterContent}
            </div>
          )}

          {isCleanable && valueInternal && (
            <div className={classes.clearButton} onClick={onClear}>
              <CloseIcon />
            </div>
          )}

          {showErrorIcon && (
            <Tooltip className={classes.info} classNameDropdown={classNameTooltip} isHovered tooltip={tooltip}>
              <ErrorIcon className={classes['warning-danger']} />
            </Tooltip>
          )}
          {showWarningIcon && (
            <Tooltip className={classes.info} classNameDropdown={classNameTooltip} isHovered tooltip={tooltip}>
              <InfoIcon className={classes[`warning-${warningColor}`]} />
            </Tooltip>
          )}
        </div>
      </div>
    )
  },
)
