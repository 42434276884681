import { MouseEventHandler, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { removeHTMLSelection } from '@jume/utils'
import { useThrottleFn } from 'ahooks'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'
import { generateNumberArray } from 'utils/generateNumberArray'

interface UseResizeColumnProps {
  setWidth?: (width: number, keys?: string[]) => void
  refMinimalWidthCell?: MutableRefObject<number | null>
  getWidthCell?: () => number | null
  onResizing?: () => void
  getCurrentIndex?: () => number
  getCurrentTypeColumn?: () => 'fixed' | 'cells'
}

export const useResizeColumn = ({
  setWidth,
  refMinimalWidthCell,
  getWidthCell,
  onResizing,
  getCurrentIndex,
  getCurrentTypeColumn,
}: UseResizeColumnProps) => {
  const { tableState } = useContext(ExcelTableContext)
  const [isResizing, setIsResizing] = useState(false)
  const refStartPosition = useRef<number | null>(null)
  const refStartWidth = useRef<number | null>(null)
  const refOnMouseMoveThrottled = useRef<((event: MouseEvent) => void) | null>(null)

  const onMouseMove = useCallback((event: MouseEvent) => {
    if (event.buttons !== 1) {
      setIsResizing(false)
      if (refOnMouseMoveThrottled.current) {
        window.removeEventListener('mousemove', refOnMouseMoveThrottled.current)
      }
      return
    }
    if (refStartPosition.current !== null) {
      let width = (refStartWidth.current || 0) + (event as MouseEvent).pageX - refStartPosition.current
      const minimalWidth = refMinimalWidthCell?.current || 0
      if (width < minimalWidth) {
        width = minimalWidth
      }
      if (width < tableState.minWidthColumn) {
        width = tableState.minWidthColumn
      }
      setWidth?.(width)
      onResizing?.()
    }
  }, [])

  const { run: onMouseMoveThrottled } = useThrottleFn(onMouseMove, { wait: 20 })

  const onMouseUp = useCallback(() => {
    setIsResizing(false)
    window.removeEventListener('mouseup', onMouseUp)
    window.removeEventListener('mousemove', onMouseMoveThrottled)
    refStartPosition.current = null
    refStartWidth.current = null
    const stateSelectedColumns = tableState.getStateSelectedColumns?.()
    if (
      stateSelectedColumns &&
      setWidth &&
      tableState.getKeyFixedByIndex &&
      tableState.getKeyCellByIndex &&
      getWidthCell &&
      getCurrentIndex &&
      getCurrentTypeColumn
    ) {
      const selected = {
        fixed: {
          start: Math.min(
            stateSelectedColumns.selectedColumns.fixed.start,
            stateSelectedColumns.selectedColumns.fixed.end,
          ),
          end: Math.max(
            stateSelectedColumns.selectedColumns.fixed.start,
            stateSelectedColumns.selectedColumns.fixed.end,
          ),
        },
        cells: {
          start: Math.min(
            stateSelectedColumns.selectedColumns.cells.start,
            stateSelectedColumns.selectedColumns.cells.end,
          ),
          end: Math.max(
            stateSelectedColumns.selectedColumns.cells.start,
            stateSelectedColumns.selectedColumns.cells.end,
          ),
        },
      }

      if (
        selected[getCurrentTypeColumn()].start > getCurrentIndex() ||
        selected[getCurrentTypeColumn()].end < getCurrentIndex()
      ) {
        return
      }

      const fixedIndexes = generateNumberArray(selected.fixed.start, selected.fixed.end).filter((index) => index >= 0)
      const fixedKeys = fixedIndexes.map(tableState.getKeyFixedByIndex).filter(Boolean)
      const cellsIndexes = generateNumberArray(selected.cells.start, selected.cells.end).filter((index) => index >= 0)
      const cellsKeys = cellsIndexes.map(tableState.getKeyCellByIndex).filter(Boolean)
      const width = getWidthCell()
      if (width) {
        fixedIndexes.forEach((index) => {
          tableState.storageWidths?.setFixedWidth(index, width)
        })
        cellsIndexes.forEach((index) => {
          tableState.storageWidths?.setWidth(index, width)
        })
        setWidth(width, [...fixedKeys, ...cellsKeys])
      }
    }
  }, [])

  const onMouseDown: MouseEventHandler = useCallback((event) => {
    setIsResizing(true)
    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('mousemove', onMouseMoveThrottled)
    refStartPosition.current = event.pageX
    refStartWidth.current = getWidthCell?.() || null
    removeHTMLSelection()
  }, [])

  useEffect(() => {
    refOnMouseMoveThrottled.current = onMouseMoveThrottled
  }, [onMouseMoveThrottled])

  return {
    onMouseDown,
    isResizing,
  }
}
