import { ReactElement, ReactNode } from 'react'

import { toArray } from '@jume/utils'

export const equalChildren = (child1: ReactNode, child2: ReactNode): boolean => {
  const array1 = toArray(child1)
  const array2 = toArray(child2)

  if (array1.length !== array2.length) {
    return false
  }
  for (let i = 0; i < array1.length; i++) {
    if (
      (array1[i] as ReactElement)?.type !== (array2[i] as ReactElement)?.type ||
      (array1[i] as ReactElement)?.key !== (array2[i] as ReactElement)?.key
    ) {
      return false
    }
  }

  return true
}
