import { CSSProperties, FC, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import cx from 'clsx'
import { checkCursorOnElement } from 'packages/app'
import { AnimationTypes } from 'packages/ui/Animation'
import { Dropdown, Position } from 'packages/ui/Dropdown'

import classes from './Tooltip.module.scss'

interface TooltipProps {
  className?: string
  classNameContainer?: string
  classNameDropdown?: string
  position?: Position
  children?: ReactNode
  tooltip?: ReactNode
  disabled?: boolean
  enabled?: boolean
  isHovered?: boolean
  onClick?: () => void
  style?: CSSProperties
  styleContainer?: CSSProperties
  cursorPointer?: boolean
  offsetX?: number
  offsetY?: number
  showImmediately?: boolean
}

export const Tooltip: FC<TooltipProps> = ({
  className,
  classNameContainer,
  classNameDropdown,
  children,
  position = 'auto',
  tooltip,
  isHovered,
  disabled,
  enabled = true,
  onClick,
  style,
  styleContainer,
  cursorPointer = true,
  offsetX,
  offsetY = 16,
}) => {
  const [open, setOpen] = useState(false)
  const [positionInternal, setPositionInternal] = useState(position)

  const refContent = useRef<HTMLDivElement>(null)
  const refTimeout = useRef<NodeJS.Timeout>()

  const onClickInternal: MouseEventHandler = () => {
    if (!disabled) {
      if (!isHovered) {
        setOpen(true)
      }
    }
    onClick?.()
  }

  const onMouseEnter = () => {
    if (isHovered && !disabled) {
      refTimeout.current = setTimeout(() => {
        if (refContent.current && checkCursorOnElement(refContent.current)) {
          setOpen(true)
        }
      }, 100)
    }
  }

  const onMouseLeave = () => {
    if (!disabled) {
      clearTimeout(refTimeout.current)
      setOpen(false)
    }
  }

  const onWindowWheel = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (open) {
      window.addEventListener('wheel', onWindowWheel)
    }
    return () => {
      window.removeEventListener('wheel', onWindowWheel)
    }
  }, [open])

  useEffect(() => {
    if (disabled) {
      clearTimeout(refTimeout.current)
      setOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    setPositionInternal(position)
  }, [position])

  useEffect(() => {
    if (refContent.current && checkCursorOnElement(refContent.current)) {
      onMouseEnter()
    }
    return () => {
      clearTimeout(refTimeout.current)
      setOpen(false)
    }
  }, [refContent.current])

  if (enabled === false) {
    return <>{children}</>
  }

  return (
    <>
      <div
        className={cx(classes.wrap, className, classes[positionInternal])}
        data-stop-propagation={isHovered ? undefined : 'true'}
        onClick={onClickInternal}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={refContent}
        style={styleContainer}
      >
        <div className={cx(classes.cont, classNameContainer, { [classes.cursorPointer]: cursorPointer })} style={style}>
          {children}
        </div>
      </div>
      {!disabled && (
        <Dropdown
          defaultCenter
          defaultTop
          hideOnWheel={false}
          offsetX={offsetX}
          offsetY={offsetY}
          onChangePosition={setPositionInternal}
          open={open}
          position={position}
          refWrap={refContent}
          setOpen={setOpen}
          typeAnimation={AnimationTypes.ScaleIn}
        >
          {open && tooltip && (
            <div className={cx(classes.tooltip, classes[positionInternal], 'scroll', classNameDropdown)}>{tooltip}</div>
          )}
        </Dropdown>
      )}
    </>
  )
}
