import { FC, PropsWithChildren, ReactNode } from 'react'

import { Link } from '@tanstack/react-router'
import cx from 'clsx'
import { Path } from 'interfaces/router.interfaces'
import { LinkProps } from 'packages/ui/Link'

import classes from './NavLink.module.scss'

interface NavLinkProps extends Omit<LinkProps, 'to' | 'id'> {
  path?: Path
  className?: string
  icon?: ReactNode
  end?: boolean
}

export const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({ path, icon, className, children, ...props }) => {
  if (!children && !icon) {
    return null
  }

  return (
    <Link
      activeOptions={{
        exact: false,
        includeSearch: false,
        includeHash: false,
      }}
      activeProps={{
        className: cx(classes.wrap, className, classes.active),
      }}
      className={cx(classes.wrap, className)}
      preload="intent"
      to={path}
      {...props}
    >
      {!!icon && <div className={classes.icon}>{icon}</div>}
      <span>{children}</span>
    </Link>
  )
}
