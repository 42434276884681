import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/prgm/phasing')({
  component: createPage({
    titleKey: 'titles.prgm.phasing',
    lazyImport: {
      fn: () => import('prgm/phasing/components/PhasingBlock'),
      name: 'PhasingBlock',
    },
  }),
})
