import { FC, isValidElement, ReactNode, useState } from 'react'
import { FieldErrors } from 'react-hook-form'

import { isObject, toArray } from '@jume/utils'
import { useDeepCompareEffect } from 'ahooks'
import cx from 'clsx'
import { cloneDeep } from 'lodash'
import { SlideToggle } from 'packages/ui/SlideToggle'

import classes from './ErrorStack.module.scss'

interface ErrorStackProps {
  className?: string
  classNameWrap?: string
  classNameMessage?: string
  errors?: string[] | string | FieldErrors | ReactNode
  enableAnimation?: boolean
}

export const ErrorStack: FC<ErrorStackProps> = ({
  className,
  classNameWrap,
  classNameMessage,
  errors,
  enableAnimation,
}) => {
  const [showError, setShowError] = useState(false)
  const [errorsArray, setErrorsArray] = useState<(string | ReactNode)[]>([])

  useDeepCompareEffect(() => {
    let s1: NodeJS.Timeout
    let s2: NodeJS.Timeout

    const errorsInternal = (
      isValidElement(errors)
        ? [errors]
        : typeof errors === 'string'
          ? [errors.trim()]
          : isObject(errors)
            ? Object.values(errors)
                .map(({ message }) => message)
                .filter((message): message is string => !!message?.trim())
            : errors
    ) as (string | ReactNode)[]

    if (errorsInternal && toArray(errorsInternal)?.length) {
      setErrorsArray(errorsInternal)
      if (!enableAnimation) {
        setShowError(true)
      } else {
        s1 = setTimeout(() => setShowError(true))
      }
    } else {
      if (showError && enableAnimation) {
        s2 = setTimeout(() => setErrorsArray([]), 200)
      } else {
        setErrorsArray([])
      }
      setShowError(false)
    }

    return () => {
      clearTimeout(s1)
      clearTimeout(s2)
    }
  }, [cloneDeep(errors)])

  return (
    <SlideToggle className={classNameWrap} duration={enableAnimation ? 200 : 0} open={showError}>
      <div className={cx(classes.wrap, className)}>
        {errorsArray.map((message, i) => (
          <div className={cx(classes.message, classNameMessage)} key={i}>
            {message}
          </div>
        ))}
      </div>
    </SlideToggle>
  )
}
