import { toArray } from '@jume/utils'

import { Effect } from './effect'

export const getEffects = (effects?: Effect | Effect[]) => ({
  effects: toArray(effects).map((effect) => effect.getFunction()),
  call(...args: any[]) {
    this.effects?.forEach((fn) => fn(...args))
  },
})
