import { memo, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { removeElementFromArray } from '@jume/utils'
import cx from 'clsx'
import { useSyncWidth } from 'hooks/useSyncWidth'
import { ExcelAxisItem, SubscribeColumnCell } from 'interfaces/excelTable.interfaces'
import { Truncate } from 'packages/ui/Truncate'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelColumnsRow } from './ExcelColumnsRow'
import classes from './ExcelTable.module.scss'
import { addDependenciesChangeColumns } from './helpers/addDependenciesChangeColumns'
import { addDependenciesColumns } from './helpers/addDependenciesColumns'
import { initWidths } from './helpers/initWidths'
import { parseColumns } from './helpers/parseColumns'
import { rotateMatrix } from './helpers/rotateMatrix'
import { setPositionsColumns } from './helpers/setPositionsColumns'

interface ExcelColumnsProps {
  page: number
  perPage: number
  setDependencies: (dependencies: Map<string, Set<string>>) => void
  setDependenciesChange: (dependenciesChange: Map<string, Set<string>>) => void
  setDependenciesMinWidth: (notDependenceMinWidth: Map<string, Set<string>>) => void
  setWidth: (keys: string[], width: number) => void
  setWidths: (widths: Record<string, number> | null) => void
  setMinimalWidths: (minimalWidths: Record<string, number> | null) => void
  getDependencies: () => Map<string, Set<string>> | null
  addCommonColumn?: (columns: ExcelAxisItem[][]) => string
  refRows?: RefObject<HTMLDivElement>
  addKeyToCells: (page: number, keys: string[]) => void
  refCommonColumn?: RefObject<HTMLDivElement>
  paginateAxis: 'x' | 'y'
  getDepthColumns: () => number
  initColumns?: ExcelAxisItem[][]
}

export const ExcelColumns = memo(
  ({
    page = 1,
    perPage,
    setDependencies,
    setDependenciesChange,
    setDependenciesMinWidth,
    setWidth,
    setWidths,
    setMinimalWidths,
    getDependencies,
    addCommonColumn,
    refRows,
    addKeyToCells,
    refCommonColumn,
    paginateAxis,
    getDepthColumns,
    initColumns,
  }: ExcelColumnsProps) => {
    const { tableState, virtualScroll } = useContext(ExcelTableContext)
    const refMaxDepth = useRef(0)

    const initializeColumns = (columnsState: ExcelAxisItem[][] | undefined) => {
      const { maxDepth, headerCells } = parseColumns(
        columnsState,
        tableState.subscribe,
        tableState.getState,
        page,
        perPage,
        paginateAxis,
        addKeyToCells,
        tableState,
      )
      addDependenciesChangeColumns(headerCells, setDependenciesChange, setDependenciesMinWidth)
      addDependenciesColumns(headerCells, setDependencies)
      initWidths(
        headerCells,
        setWidths,
        setMinimalWidths,
        tableState.minWidthColumn,
        getDependencies,
        tableState,
        paginateAxis === 'x' ? page : 1,
        perPage,
      )
      setPositionsColumns(headerCells, tableState, page, perPage, paginateAxis)
      refMaxDepth.current = maxDepth

      return {
        initParsedColumns: rotateMatrix(headerCells).map((row) => row.filter(Boolean)),
        initCommonColumn:
          page === 1 && addCommonColumn && columnsState && getDepthColumns() > 1
            ? addCommonColumn(columnsState)
            : undefined,
        maxDepth,
      }
    }

    const { initParsedColumns, initCommonColumn } = useMemo(() => initializeColumns(initColumns), [initColumns])

    const [columns, setColumns] = useState<SubscribeColumnCell[][]>(initParsedColumns)
    const [commonColumn, setCommonColumn] = useState<string | undefined>(initCommonColumn)
    const refCommonColumnText = useRef<HTMLDivElement>(null)
    const [ready, setReady] = useState(true)

    const onChangeReady = useCallback((newReady: boolean) => {
      setReady(newReady)
    }, [])

    useSyncWidth(refRows, refCommonColumnText, -16, [commonColumn])

    useEffect(
      () =>
        tableState.subscribe?.(
          ({ data }) => data?.[page]?.columns,
          (columnsState: ExcelAxisItem[][] | undefined) => {
            if (columnsState === initColumns) {
              return
            }
            const {
              initParsedColumns: newColumns,
              initCommonColumn: newCommonColumn,
              maxDepth,
            } = initializeColumns(columnsState)
            setColumns(newColumns)
            refMaxDepth.current = maxDepth
            if (newCommonColumn) {
              setCommonColumn(newCommonColumn)
            }
          },
        ),
      [tableState.subscribe],
    )

    useEffect(() => {
      virtualScroll.onChangeReady.push(onChangeReady)
      return () => removeElementFromArray(virtualScroll.onChangeReady, onChangeReady)
    }, [onChangeReady])

    return (
      <>
        <div className={cx(classes.commonHeader)} ref={refCommonColumn} style={{ transform: 'translate(0)' }}>
          {commonColumn && (
            <div className={cx(classes.cell, classes.hearer)}>
              <div className={classes.commonText} ref={refCommonColumnText}>
                <Truncate>{commonColumn}</Truncate>
              </div>
            </div>
          )}
          <div
            className={cx({
              [classes.headersBg]: !commonColumn && refCommonColumn,
              [classes.headersBgLight]: commonColumn || !refCommonColumn,
            })}
            style={{
              height: refMaxDepth.current * tableState.heightRow,
              top: commonColumn || !refCommonColumn ? tableState.heightRow : 0,
            }}
          />
        </div>
        <div
          style={page > 1 && addCommonColumn && columns.length > 1 ? { marginTop: tableState.heightRow } : undefined}
        >
          {columns.map((row, indexRow) => (
            <ExcelColumnsRow
              columns={row}
              disableSelect={indexRow !== columns.length - 1}
              getDepthColumns={getDepthColumns}
              indexRow={indexRow}
              isCommonColumn={!!addCommonColumn && columns.length > 1}
              key={indexRow}
              page={page}
              ready={ready}
              setWidth={setWidth}
            />
          ))}
        </div>
      </>
    )
  },
)
