import { colors } from 'constants/colors'
import { TargetCalculate } from 'prgm/scorecard/interfaces'

export const scenarioKeyToTitle: Record<keyof Extract<TargetCalculate, 'dateTitle'>, string> = {
  coUplift: 'CO uplift, kRub',
  dodPercentage: 'DoD, %',
  elasticityPercentage: 'Elasticity, %',
  nsInvPercentage: 'NS/INV',
  nsUplift: 'NS uplift, kRub',
  promoBaseline: 'Promo base, tn',
  promoSaturationPercentage: 'Promo saturation, %',
  promoSpend: 'Promo spend, krub',
  promoSpentPercentage: 'Promo spend, %',
  totalVolume: 'Volume, tn',
  roiPercentage: 'ROI, %',
  totalBaseline: 'Baseline, tn',
  totalGs: 'Total GS, kRub',
  promoVolume: 'VOD, tn',
  uplift: 'Uplift, tn',
  upliftPercentage: 'Uplift, %',
  volumeGs: 'VOD GS',
  volumePercentage: 'VOD, %',
}

export enum Views {
  YearToYear = 'YEAR_TO_YEAR',
  Linear = 'LINEAR',
}

export const diagramTypeToChartType = {
  colum: 'column',
  bar: 'line',
}

export const diagramTypeToColor = {
  column: colors.line10,
  line: colors.black,
  line1: colors.line11,
  column1: colors.line3,
}
