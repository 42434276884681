import { deleteEmpty } from '@jume/utils'
import { GetAllData } from 'packages/api'
import { Profile } from 'packages/app/modules/profile'
import { axios, GetAllResponse, GetOneResponse, Pagination } from 'packages/core'
import { defaultPagination } from 'utils/pagination'

import { projectsEndpoints } from './endpoints'
import { CurrentProjectUpdateData, Project, ProjectInfo } from './interfaces'

const getProject = async (projectId: number): GetOneResponse<Project> => {
  const { data } = await axios.request<Project>({
    method: 'GET',
    ...projectsEndpoints.getProject(projectId),
    tracing: {
      stream: null,
      module: 'app.projects',
      methodName: 'getProject',
    },
  })
  return data
}

const getProjectInfo = async (projectId: number): GetOneResponse<ProjectInfo> => {
  const { data } = await axios.request<ProjectInfo>({
    method: 'GET',
    ...projectsEndpoints.getProjectInfo(projectId),
    tracing: {
      stream: null,
      module: 'app.projects',
      methodName: 'getProjectInfo',
    },
  })
  return data
}

const searchProject = async (search?: string, pagination?: Pagination): GetAllResponse<Project> => {
  const { data } = await axios.request<GetAllData<Project>>({
    method: 'GET',
    ...projectsEndpoints.getProjects(),
    params: deleteEmpty({
      ...defaultPagination(pagination),
      title: search,
    }),
    tracing: {
      stream: null,
      module: 'app.projects',
      methodName: 'searchProject',
    },
  })
  return data
}

const updateCurrentProject = async (data: CurrentProjectUpdateData): GetOneResponse<Profile> => {
  const { data: response } = await axios.request<Profile>({
    method: 'PATCH',
    ...projectsEndpoints.updateCurrentProject(),
    tracing: {
      stream: null,
      module: 'app.projects',
      methodName: 'updateCurrentProject',
    },
    data,
  })
  return response
}

export const projectsApi = {
  getProject,
  getProjectInfo,
  searchProject,
  updateCurrentProject,
}
