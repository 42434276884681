import { useCallback, useContext, useEffect } from 'react'

import { removeElementFromArray } from '@jume/utils'
import { useSafeState } from 'ahooks'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'

export const useCellsReady = () => {
  const { virtualScroll } = useContext(ExcelTableContext)
  const [ready, setReady] = useSafeState(false)

  const onChangeReady = useCallback((newReady: boolean) => {
    setReady(newReady)
  }, [])

  useEffect(() => {
    setTimeout(() => onChangeReady(true), 250)
    virtualScroll.onChangeReady.push(onChangeReady)
    return () => removeElementFromArray(virtualScroll.onChangeReady, onChangeReady)
  }, [onChangeReady])

  return { ready }
}
