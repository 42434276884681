import { CSSProperties } from 'react'

import { deleteEmpty } from '@jume/utils'
import { Column, ColumnMeta } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

export const getColumnProps = <TData extends TRow>(column: Column<TData>) => {
  const props = (column.columnDef.meta || {}) as ColumnMeta
  const columnRadiusStyles: CSSProperties = deleteEmpty({
    borderRadius: props.style?.borderRadius,
  })
  const tdStyles: CSSProperties = deleteEmpty({
    ...props.style,
    borderRadius: undefined,
  })
  return {
    columnProps: {
      style: columnRadiusStyles,
    },
    tdProps: {
      ...props,
      style: tdStyles,
    },
  }
}
