import { FC, HTMLAttributes, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import { Row } from '@tanstack/table-core'
import { useClickAway } from 'ahooks'
import { TextFieldColors } from 'interfaces/components.interfaces'
import { TRow } from 'interfaces/table.interfaces'
import { getRenderedRows } from 'packages/ui/Table/helpers/getRenderedRows'
import { useMatrix } from 'packages/ui/Table/hooks/useMatrixEditable'
import { SelectableCellParams } from 'packages/ui/Table/hooks/useSelectableCell'
import { InitContextMenu, OnRowClick, OnRowContextMenu } from 'packages/ui/Table/interfaces'
import { CollapsingStrategy, DraggableStrategy, RowId } from 'packages/ui/Table/types'

interface GetRenderedRowsProps<TData extends TRow> {
  virtualize?: boolean
  virtualizeColumn?: boolean
  rows: Row<TData>[]
  mayReturnIds?: number[]
  archivedIds?: number[]
  deleteActiveIds?: any[]
  inputHasChanged?: boolean
  isCollapsing?: boolean
  collapsingComponent?: FC<{ row?: TData }>
  isCollapsedRowInternal: (row: TData) => boolean
  expandedRowsInternal: RowId[]
  onRow?: (row: TData) => HTMLAttributes<HTMLTableRowElement>
  rowClassName?: string
  rowSelectedClassName?: string
  canReturnFromArchive?: boolean
  showCountRowsInfo?: boolean
  forceDeleteTooltip?: boolean
  classNameExpanded?: string
  classesRows?: Record<string, number[]>
  toggleCollapse: (rowId: RowId, expanded?: boolean) => void
  selectPosition?: 'left' | 'right'
  renderSelect: (row: any) => ReactNode
  showFooterInternal?: boolean
  onRowClick?: OnRowClick<TData>
  onRowContextMenu?: OnRowContextMenu<TData>
  initContextMenu?: InitContextMenu
  columnResizable?: boolean
  dashIfEmpty?: boolean
  matrix: ReturnType<typeof useMatrix>
  getType?: (value: string) => TextFieldColors
  data?: TData[] | null
  deletable?: boolean
  onDelete?: (id: number) => void
  deleteInactiveTooltip?: ReactNode
  selectable?: boolean
  onReturn?: (id: number) => void
  deleteIconClassName?: string
  onRemoveNew?: (index: number) => void
  createdRows?: TData[] | null
  CollapsingComponent?: FC<{ row?: TData }>
  classNameCollapsedRow?: string
  colSpan: number
  getMeta?: () => any
  selectedIds?: any[]
  errorsIds?: RowId[]
  disabledIds?: RowId[]
  collapsingTreeKeys?: string[]
  collapsingStrategy?: CollapsingStrategy
  isCollapsed?: (rowId: RowId) => boolean
  isParent?: (rowId: RowId) => boolean
  isMouseOverParent?: (rowId: RowId) => void
  isMouseOutParent?: () => void
  hoverParent?: RowId
  isDraggable?: boolean
  draggableStrategy?: DraggableStrategy
  virtualRows?: VirtualItem[]
  virtualColumns?: VirtualItem[]
  rowVirtualizer?: Virtualizer<any, any>
  columnVirtualizer?: Virtualizer<any, any>
  showStatus?: boolean
  totalSize?: number
  sizeLastColumn?: number | null
  selectableCellParams?: SelectableCellParams
  tableRef?: RefObject<HTMLTableElement>
  refWrap?: RefObject<HTMLDivElement>
  selectableRowsForDrag?: boolean
}

export const useRenderedRows = <TData extends TRow>({
  virtualize,
  virtualRows,
  rowVirtualizer,
  columnVirtualizer,
  virtualColumns,
  virtualizeColumn,
  refWrap,
  ...props
}: GetRenderedRowsProps<TData>) => {
  const commonDeps = Object.values(props)
  const [selectableRowsForDragData, setSelectableRowsForDragData] = useState<Record<string | number, any>>({})
  const lastSelectRowForDragIdsRef = useRef<RowId>(null)

  useClickAway(
    () => {
      setSelectableRowsForDragData({})
    },
    [refWrap],
    ['mousedown', 'mouseup'],
  )

  const virtualRenderedRows = useMemo(
    () =>
      virtualize
        ? getRenderedRows({
            ...props,
            virtualRows,
            rowRef: rowVirtualizer?.measureElement,
            selectableRowsForDragData,
            setSelectableRowsForDragData,
            lastSelectRowForDragIdsRef,
            columnVirtualizer,
            virtualColumns,
            virtualizeColumn,
          })
        : null,
    [
      ...commonDeps,
      virtualize,
      virtualizeColumn,
      virtualRows?.length,
      rowVirtualizer?.getTotalSize(),
      selectableRowsForDragData,
      virtualColumns?.length,
      columnVirtualizer?.getTotalSize(),
    ],
  )

  const renderedRows = useMemo(
    () =>
      virtualize
        ? null
        : getRenderedRows({
            ...props,
            selectableRowsForDragData,
            setSelectableRowsForDragData,
            lastSelectRowForDragIdsRef,
          }),
    [...commonDeps, virtualize, selectableRowsForDragData],
  )

  useEffect(() => {
    if (props.selectableCellParams?.setObserver && props.tableRef?.current) {
      props.selectableCellParams.setObserver((x, y) => {
        props.tableRef?.current
          ?.querySelectorAll('[data-selected="true"]')
          ?.forEach((el) => el.removeAttribute('data-selected'))
        if (typeof x === 'number' && typeof y === 'number') {
          props.tableRef?.current
            ?.querySelector(`:scope > tbody > tr:nth-child(${y + 1}) > td:nth-child(${x + 1})`)
            ?.setAttribute('data-selected', 'true')
        }
      })
    }
  }, [props.selectableCellParams, props.tableRef?.current])

  return { renderedRows, virtualRenderedRows, selectableRowsForDragData }
}
