import { useContext, useEffect, useRef } from 'react'

import { toCamelCase } from '@jume/utils'
import { Subscription } from 'centrifuge'
import { filesize } from 'filesize'
import { AppContext } from 'packages/app'
import { ws } from 'packages/core'
import { GlobalSettings } from 'packages/core/config'
import { sendWsTracing } from 'packages/core/tracing'

export interface UseWsOptions {
  enabled?: boolean
  onPublish?: Record<string | 'default', (data: any, response?: any) => void>
  tracing?: Record<string, string | number | null>
  stream?: GlobalSettings['streams'] | null
  module?: string
  userId?: number
}

export const useWs = (channel: string, options?: UseWsOptions) => {
  const appContext = useContext(AppContext)
  const refSubscription = useRef<Subscription | null>(null)
  const enabled = !!options?.userId && (options?.enabled ?? true)

  useEffect(() => {
    const centrifuge = ws.getInstance()
    if (!centrifuge) {
      return
    }
    if (!enabled) {
      return () => {
        if (refSubscription.current) {
          refSubscription.current.unsubscribe()
          centrifuge.removeSubscription(refSubscription.current)
        }
      }
    }
    const sub = centrifuge.newSubscription(channel)
    refSubscription.current = sub
    sub.subscribe()

    sub.on('publication', function (ctx) {
      if (ctx.data === undefined) {
        return
      }
      options?.onPublish?.[ctx.data?.type || 'default']?.(ctx.data?.data ?? ctx.data?.value, toCamelCase(ctx.data))
      const data = toCamelCase(ctx.data)
      if (appContext.global.ENABLE_TRACING) {
        sendWsTracing({
          data,
          channel,
          tracing: options?.tracing,
          userId: options?.userId,
          stream: options?.stream,
          module: options?.module,
          size: filesize(new Blob([JSON.stringify(data)]).size),
        })
      }
    })

    if (centrifuge.state !== 'connected') {
      centrifuge.connect()
    }

    return () => {
      sub.unsubscribe()
      centrifuge.removeSubscription(sub)
    }
  }, [enabled])
}
