import { I18N } from '@ayub-begimkulov/i18n'
import { global } from '@jume/utils'

import { KeySetsMap, Localization } from './i18n'
import { systemLang } from './systemLang'
import { Languages, LocalizationKeySets } from './types'

export class LocalizationState {
  private localization: Localization | null = null

  setLocalization(localization: Localization) {
    this.localization = localization
  }

  getLocalization() {
    return this.localization
  }
}

export const localizationState: LocalizationState = global.localizationState ?? new LocalizationState()

if (!global.localizationState) {
  global.localizationState = localizationState
}

export const t = (...args: Parameters<I18N<KeySetsMap<LocalizationKeySets['locals']>>['get']>) =>
  global.localizationState?.getLocalization()?.t(...args) || args[0]

export const setLang = async (lang: Languages | string) => {
  const i18n = localizationState?.getLocalization()?.i18n
  if (i18n) {
    await i18n.setLang(lang)
  }
}

export const getLang = (): Languages => {
  const i18n = localizationState?.getLocalization()?.i18n
  return (i18n?.getLang() as Languages) || systemLang
}
