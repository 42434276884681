import { memo, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { removeElementFromArray } from '@jume/utils'
import cx from 'clsx'
import { ExcelAxisItem } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelFixedColumns } from './ExcelFixedColumns'
import { ExcelFixedPages } from './ExcelFixedPages'
import { ExcelFixedRows } from './ExcelFixedRows'
import classes from './ExcelTable.module.scss'
import { useResizeColumn } from './handlers/useResizeColumn'

interface ExcelFixedBlockProps {
  setWidth: (keys: string[], width: number, isInit?: boolean) => void
  setWidths: (widths: Record<string, number> | null) => void
  getWidths: () => Record<string, number> | null
  getDepth?: () => number
  refFixedRows: RefObject<HTMLDivElement>
  countX: number
  countY: number
  perPage: number
  paginateAxis: 'x' | 'y'
}

export const ExcelFixedBlock = memo(
  ({
    setWidth,
    setWidths,
    getWidths,
    getDepth,
    refFixedRows,
    countX,
    countY,
    perPage,
    paginateAxis,
  }: ExcelFixedBlockProps) => {
    const { tableState, virtualScroll } = useContext(ExcelTableContext)

    const [depth, setDepth] = useState(getDepth?.() || 1)
    const [ready, setReady] = useState(true)

    const onChangeReady = useCallback((newReady: boolean) => {
      setReady(newReady)
    }, [])

    const refLastCell = useRef<ExcelAxisItem | undefined>()
    const refLastCellWidth = useRef<number | null>(null)

    const { onMouseDown, isResizing } = useResizeColumn({
      setWidth: (width, keys?: string[]) => {
        if (keys) {
          setWidth(keys, width)
        } else if (refLastCell.current?.code) {
          setWidth([refLastCell.current.code], width)
          tableState.storageWidths?.setFixedWidthByKey(refLastCell.current.code, width)
        }
        refLastCellWidth.current = width
      },
      getWidthCell: () => refLastCellWidth.current,
      getCurrentIndex: () => (tableState.getState?.().data?.[1]?.rows?.[0].length || 0) - 1,
      getCurrentTypeColumn: () => 'fixed',
    })

    useEffect(
      () =>
        tableState.subscribe?.(
          ({ data }) => {
            const firstRow = data?.[1]?.rows?.[0]
            if (!firstRow) {
              return null
            }
            return firstRow[firstRow.length - 1]
          },
          (lastCell: ExcelAxisItem | undefined) => {
            refLastCell.current = lastCell
            refLastCellWidth.current =
              lastCell?.initWidth ||
              (lastCell?.code && getWidths()?.[lastCell.code]) ||
              tableState.defaultWidthColumn ||
              null
          },
        ),
      [],
    )

    useEffect(() => {
      virtualScroll.onChangeReady.push(onChangeReady)
      return () => removeElementFromArray(virtualScroll.onChangeReady, onChangeReady)
    }, [onChangeReady])

    return (
      <div className={classes.fixedBlock} style={{ paddingTop: (depth - 1) * 32 }}>
        <div className={classes.fixedWhite} style={{ height: depth * 32 }} />
        <div className={classes.fixedBlock}>
          <ExcelFixedColumns
            getDepth={getDepth}
            initColumns={tableState.getState?.().data?.[1]?.rows?.[0]}
            ready={ready}
            setDepth={setDepth}
            setWidth={setWidth}
            setWidths={setWidths}
          />
          <div ref={refFixedRows} style={{ transform: 'translateY(0)', height: countY * tableState.heightRow }}>
            {paginateAxis === 'y' ? (
              <ExcelFixedPages
                countX={countX}
                countY={countY}
                paginateAxis={paginateAxis}
                perPage={perPage}
                ready={ready}
              />
            ) : (
              <ExcelFixedRows
                countX={countX}
                countY={countY}
                initCells={tableState.getState?.().data?.[1]?.rows}
                page={1}
                paginateAxis={paginateAxis}
                perPage={perPage}
                ready={ready}
              />
            )}
          </div>
          <div
            className={cx(classes.fixedResize, { [classes.isResizing]: isResizing })}
            data-not-selected="true"
            onMouseDown={onMouseDown}
          />
        </div>
      </div>
    )
  },
)
