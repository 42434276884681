import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const jobsModule: ModuleConnector = (options, customOptions) => ({
  ...options,
  menuItems: {
    jobs: {
      label: () => t('menu.jobs'),
      items: [
        {
          label: () => t('menu.schedule'),
          href: `/${customOptions?.streamName}/jobs`,
        },
        {
          label: () => t('menu.status'),
          href: `/${customOptions?.streamName}/jobs-status`,
        },
      ],
      dependence: (appContext) =>
        appContext.modules.permissions?.readJobsDbPrgm ||
        appContext.modules.permissions?.editJobsDbPrgm ||
        appContext.modules.permissions?.readJobsDbDemand ||
        appContext.modules.permissions?.editJobsDbDemand ||
        appContext.modules.permissions?.readJobsDbDeploy ||
        appContext.modules.permissions?.editJobsDbDeploy,
    },
  },
})
