import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const deployScenarioModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    scenarios: {
      label: () => t('deploy.navMenu.scenarios'),
      initialOpen: true,
      items: [
        {
          label: () => t('deploy.navMenu.scenarios.create'),
          href: `/${Streams.Deploy}/create-scenario`,
        },
        {
          label: () => t('deploy.navMenu.scenarios.management'),
          href: `/${Streams.Deploy}/scenario-data-hub`,
        },
        {
          label: () => t('deploy.navMenu.scenarios.settings'),
          href: `/${Streams.Deploy}/scenario-settings`,
        },
        {
          label: () => t('deploy.navMenu.deployment.allocation'),
          href: '/deploy/stock-allocation',
        },
        {
          label: () => t('deploy.navMenu.deployment.optimization'),
          href: '/deploy/transport-optimization',
        },
      ],
    },
  },
})
